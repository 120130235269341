<template>
    <div class="weui-panel weui-panel_access" style="padding-bottom: 50px; height: 100%">
        <mt-navbar v-model="selected_tab">
            <mt-tab-item id="1">共享订单</mt-tab-item>
            <mt-tab-item id="2">个人订单</mt-tab-item>
        </mt-navbar>
        <mt-tab-container v-model="selected_tab">
            <mt-tab-container-item id="1">
                <div class="weui-panel__bd" style="margin-top: 10px">
                    <div v-if="no_shared_data" class="weui-loadmore weui-loadmore_line" style="margin-top: 50px">
                        <div class="weui-loadmore__tips weui-loadmore__tips_in-line">暂无订单</div>
                    </div>
                    <div v-for="(item, index) in shared_order_list" v-bind:key="index">
                        <div
                            v-on:click="choose_shared_detail(item.oid)"
                            class="weui-media-box weui-media-box_appmsg"
                            hover-class="weui-cell_active"
                        >
                            <div class="weui-media-box__hd weui-media-box__hd_in-appmsg">
                                <img class="weui-media-box__thumb" :src="item.opic" />
                            </div>
                            <div class="weui-media-box__bd weui-media-box__bd_in-appmsg">
                                <div class="weui-media-box__title">
                                    {{ item.oid }}
                                </div>
                                <div class="weui-media-box__desc">
                                    {{ item.odesc }}
                                </div>
                                <div class="weui-media-box__info" style="margin-top: 15rpx; padding-bottom: 0">
                                    <div class="weui-media-box__info__meta">
                                        {{ item.create_time }}
                                    </div>
                                    <div class="weui-media-box__info__meta weui-media-box__info__meta_extra">
                                        {{ item.ostatus }}
                                    </div>
                                    <div class="weui-media-box__info__meta weui-media-box__info__meta_extra">
                                        ${{ item.oprice }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!no_shared_data" class="weui-loadmore weui-loadmore_line">
                    <div class="weui-loadmore__tips weui-loadmore__tips_in-line">我是底线</div>
                </div>
            </mt-tab-container-item>
            <mt-tab-container-item id="2">
                <div class="weui-panel__bd" style="margin-top: 10px">
                    <div v-if="no_data" class="weui-loadmore weui-loadmore_line" style="margin-top: 50px">
                        <div class="weui-loadmore__tips weui-loadmore__tips_in-line">暂无订单</div>
                    </div>
                    <div v-for="(item, index) in order_list" v-bind:key="index">
                        <div
                            v-on:click="choose_detail(item.oid)"
                            class="weui-media-box weui-media-box_appmsg"
                            hover-class="weui-cell_active"
                        >
                            <div class="weui-media-box__hd weui-media-box__hd_in-appmsg">
                                <img class="weui-media-box__thumb" :src="item.opic" />
                            </div>
                            <div class="weui-media-box__bd weui-media-box__bd_in-appmsg">
                                <div class="weui-media-box__title">
                                    {{ item.oid }}
                                </div>
                                <div class="weui-media-box__desc">
                                    {{ item.odesc }}
                                </div>
                                <div class="weui-media-box__info" style="margin-top: 15rpx; padding-bottom: 0">
                                    <div class="weui-media-box__info__meta">
                                        {{ item.create_time }}
                                    </div>
                                    <div class="weui-media-box__info__meta weui-media-box__info__meta_extra">
                                        {{ item.ostatus }}
                                    </div>
                                    <div class="weui-media-box__info__meta weui-media-box__info__meta_extra">
                                        ${{ item.oprice }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!no_data" class="weui-loadmore weui-loadmore_line">
                    <div class="weui-loadmore__tips weui-loadmore__tips_in-line">我是底线</div>
                </div>
            </mt-tab-container-item>
        </mt-tab-container>
    </div>
</template>
<script>
import '../assets/style/orders.css';
import Vue from 'vue';
import { Navbar, TabItem, TabContainer, TabContainerItem } from 'mint-ui';

Vue.component(TabContainer.name, TabContainer);
Vue.component(TabContainerItem.name, TabContainerItem);
Vue.component(Navbar.name, Navbar);
Vue.component(TabItem.name, TabItem);

// import { Indicator } from 'mint-ui';
// // Indicator.open('加载中');
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'orders',
    data() {
        return {
            selected_tab: '1'
        };
    },
    computed: {
        ...mapGetters({
            no_shared_data: 'orders/check_if_has_shared_orders',
            no_data: 'orders/check_if_has_orders',
            order_list: 'orders/get_order_list',
            shared_order_list: 'orders/get_shared_order_list'
        })
    },
    created() {
        this.initData({
            sid: this.$store.state.app.sid,
            uid: this.$store.state.app.uid,
            type: this.$store.state.app.type
        });
    },
    methods: {
        choose_detail(oid, evt) {
            this.$router.push({ path: '/sub/orderdetail?oid=' + oid });
        },
        choose_shared_detail(oid, evt) {
            this.$router.push({ path: '/sub/sharedorderdetail?oid=' + oid });
        },
        ...mapActions({
            initData: 'orders/initData'
        })
    }
};
</script>
